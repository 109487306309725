/* eslint-disable jsx-a11y/label-has-associated-control */
import './PersonalAgreement.scss';
import React, {FC, useCallback} from 'react';
import {MtsDsCheckbox} from '@mts-ds/granat-react';
import {Link} from 'react-router-dom';

interface PersonalAgreementProps {
  callback: (...args: any) => void;
  field: string;
  invalid?: boolean;
}

export const PersonalAgreement: FC<PersonalAgreementProps> = ({callback, invalid, field}) => {
  const onCheckboxStateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: {checked},
      } = event;
      callback(checked, field);
    },
    [callback, field],
  );
  return (
    <div>
      <div className="personal-data">
        <MtsDsCheckbox size="m" onChange={onCheckboxStateChange} invalid={invalid} />
        <label>
          Я принимаю
          <Link className="personal-data__link" to="personal-data-agreement" target="_blank"> условия пользовательского соглашения
          </Link>
        </label>
      </div>
      {invalid && (
        <div className="personal-data__invalid">
          <span>Для входа на сервис нужно принять условия соглашения</span>
        </div>
      )}
    </div>
  );
};
